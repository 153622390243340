import React from "react";
import { useRecommendation } from "../../../contexts/use-recommendation";

function RecommendationFeedbackSubmitPage() {
    const recommendationContext = useRecommendation();
    const {setCurrentPage} = recommendationContext;

    function handleNoMember() {
        setCurrentPage(1);
    }

    return (
        <div className="text-center">
            <div className="mb-8">
                <div className="my-5">
                    <p>Suas indicações foram enviadas com sucesso.</p>
                    <p><strong>Obrigado pela participação.</strong></p>
                    <p>Ore por nossas Eleições.<br />(1 TIMÓTEO 3.1-15)</p>
                    <p className="mt-5"><strong>Para visualizar ou acrescentar suas indicações, volte ao início.</strong></p>
                </div>
            </div>
            <div>
                <button type="button" className="bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded" onClick={handleNoMember}>Início</button>
            </div>
        </div>
    )
}

export default RecommendationFeedbackSubmitPage;

