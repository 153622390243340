import React from "react";
import { DELIBERATION_LOGIN } from "../../../constants";
import { useDeliberation } from "../../../contexts";

function DeliberationInvalidPasswordPage() {
    const deliberationContext = useDeliberation();
    const { setCurrentPage } = deliberationContext;

    return (
        <div className="text-center">
            <div className="mb-5">
                <p>Senha de votação inválida. Tenha em mãos a senha recebida do mesário e tente informá-la novamente.</p>
            </div>
            <div className="text-center">
                <button
                    type="button"
                    className={`bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded`}
                    onClick={() => setCurrentPage(DELIBERATION_LOGIN)}
                >
                    Início
                </button>
            </div>
        </div>
    )
}

export default DeliberationInvalidPasswordPage;

