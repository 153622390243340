/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  createContext,
  FunctionComponent,
  useState,
  useEffect,
} from 'react';
import { FirebaseProps } from './use-firebase.props';
import firebaseApp from 'firebase/app';
import 'firebase/firebase-firestore';
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const firebaseContext = createContext({} as FirebaseProps);

export const useFirebase = (): FirebaseProps => {
  return useContext(firebaseContext);
};


function useProvideFirebase(): FirebaseProps {
  // let unsubscribeOnAuthStateChanged: any;
  const [loading, setLoading] = useState<boolean>(true);
  const [db, setDb] = useState<firebase.firestore.Firestore>();

  useEffect(() => {
    firebaseApp.initializeApp(config);
    firebaseApp.analytics();
    setDb(firebaseApp.firestore());
    setLoading(false);
  }, []);

  return {
    loading,
    db
  };
}

export const ProvideFirebase: FunctionComponent = ({ children }) => {
  const firebase: FirebaseProps = useProvideFirebase();
  return (
    <firebaseContext.Provider value={firebase}>
      {children}
    </firebaseContext.Provider>
  );
};
