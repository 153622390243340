import React, { useEffect, useState } from "react";
import { useRecommendation } from "../../../contexts/use-recommendation";
import { COLLECTION_CHURCH, COLLECTION_CHURCH_RECOMMENDATION } from "../../../constants";
import { Modal } from 'antd';
import { useFirebase } from "../../../contexts";
import firebase from "firebase/app";

function RecommendationRecommendationPage() {
    const firebaseContext = useFirebase();
    const recommendationContext = useRecommendation();
    const { church, data, user, setCurrentPage } = recommendationContext;
    const [showConfirm, setShowConfirm] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [isFull, setIsFull] = useState(false);
    const [voting, setVoting] = useState<Array<any>>([]);
    const [formVotes, setFormVotes] = useState<Array<any>>([]);
    const collection = `${COLLECTION_CHURCH}/${church.id}/${COLLECTION_CHURCH_RECOMMENDATION}/${data.id}/voting`;

    useEffect(() => {
        setLoading(true);
        const unsubscribe: any = firebaseContext
            ?.db
            ?.collection(collection)
            .where('voter-cpf', '==', user.cpf)
            .onSnapshot((resp) => {
                const newArr: Array<any> = [];
                if (!resp.empty) {
                    resp.forEach((item: any) => {
                        const data$ = item.data();
                        newArr.push({
                            id: item.id,
                            ...data$
                        });
                    });
                }
                const full: any = [];
                data?.responsibility.forEach((responsibility: any) => {
                    const filtered = newArr.filter((item) => item.responsibility === responsibility.id);
                    full.push(filtered.length === responsibility.qty)
                });

                if (!full.includes(false)) {
                    setIsFull(true);
                };

                setVoting(newArr);
                setLoading(false);
            });

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, []);

    useEffect(() => {
        if (hasFocus) {
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            }
        }
    }, [hasFocus])

    function handleScroll() {
        const el: any = document?.activeElement;
        el.blur();
    }

    function handleBack() {
        setCurrentPage(1);
    }

    function handleSubmit(e: any, submit = false) {
        if (e) {
            e.preventDefault();
        }
        if (!isFull) {
            const arr: any = [];
            const filtered = Object.keys(formVotes).map((item: any) => {
                return formVotes[item].filter((el: any) => el)
            }); //limpa arrays vazios
            filtered.forEach((group) => {
                group.forEach((vote: any) => {
                    if (vote?.name) {
                        vote.createAt = firebase.firestore.FieldValue.serverTimestamp();
                        arr.push(vote);
                    }
                })
            })
            if (arr.length > 0) {
                if (!submit) {
                    setShowConfirm(true);
                    return
                }
                setSending(true);
                const db = firebase.firestore();
                const batch = db.batch();
                arr.forEach((doc: any) => {
                    const docRef = db.collection(collection).doc(); //automatically generate unique id
                    batch.set(docRef, doc);
                });
                batch.commit()
                    .then(() => {
                        setCurrentPage(5);
                    })
                    .catch((err) => {
                        alert('Algo deu errado. Tente novamente.')
                    })
                    .finally(() => {
                        setSending(false);
                    })
            }
        }
    }

    function handleChange(id: number, index: number, value: string) {
        const arr: any = { ...formVotes };
        let item = undefined;
        if (value.trim()) {
            item = {
                responsibility: id,
                name: value,
                'voter-name': user.name,
                'voter-cpf': user.cpf,
                'voter-phone': user.phone,
            }
        }

        try {
            arr[id][index] = item;
        } catch {
            arr[id] = [];
            arr[id][index] = item
        }

        setFormVotes({ ...arr });
    }

    if (loading) {
        return (
            <div className="py-20">
                <p className="text-center">Carregando...</p>
            </div>
        );
    }


    return (
        <>
            <div className="w-full">
                <form className="max-w-xs mx-auto mt-3" onSubmit={handleSubmit}>
                    {data?.responsibility.map((responsibility: any) => {
                        const filtered = voting.filter((item) => item.responsibility === responsibility.id);
                        const voted: Array<any> = Array.from(Array(filtered.length).keys());
                        const newVotes: Array<any> = Array.from(Array(responsibility.qty - voted.length || 0).keys());
                        return (
                            <div className="mb-6 border-b border-primary-base pb-5" key={responsibility.id}>
                                <label className="block text-sm mb-3">
                                    Para <strong>{responsibility.name.toUpperCase()}</strong>, indicar até {responsibility.qty} nomes. <br />
                                    <small>Você já indicou {filtered.length} de {responsibility.qty} nomes</small>
                                </label>
                                {voted.map((vote: any, votedIndex: number) => (
                                    <div className="relative mb-3" key={votedIndex}>
                                        <p className="appearance-none cursor-not-allowed opacity-50 rounded w-full py-3 px-3 bg-blue-100 leading-tight">
                                            {votedIndex + 1}. {filtered[votedIndex].name}
                                        </p>
                                        <div className="ml-4 text-green-400 text-xl rounded px-4 absolute top-0 right-0 h-full flex items-center pointer-events-none">
                                            <i className="uil uil-user-check"></i>
                                        </div>
                                    </div>
                                ))}
                                {newVotes.map((votes: any, votesIndex: number) => {
                                    return (
                                        <div className="relative mb-3" key={votesIndex}>
                                            <input
                                                autoComplete="nofill"
                                                type="text"
                                                name={`name-${responsibility.id}-${votesIndex}`}
                                                onFocus={() => setTimeout(() => { setHasFocus(true) }, 500)}
                                                onBlur={() => setHasFocus(false)}
                                                // value={formVotes[responsibility?.id][votesIndex]}
                                                placeholder={`${(voted.length || 0) + votesIndex + 1}. Nome para ${responsibility?.name}`}
                                                onChange={(e: any) => handleChange(responsibility?.id, votesIndex, e.target.value)}
                                                // className="shadow appearance-none border rounded w-full py-3 px-3 text-primary-base text-primary-base leading-tight focus:outline-none focus:shadow-outline"
                                                className="shadow appearance-none w-full py-3 px-3 text-primary-base placeholder-primary-base leading-tight focus:outline-none focus:shadow-outline bg-gray-100 placeholder-opacity-25"
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                    <div className="flex justify-between items-center">
                        <button type="button" className="text-primary-base font-bold transform hover:scale-105 transition duration-500 py-3 rounded mr-4 focus:outline-none" onClick={handleBack}>{'< '}{isFull ? 'Voltar' : 'Cancelar'} </button>
                        {
                            !isFull && (
                                <button type="submit" disabled={sending} className={`bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded ml-4 ${sending ? 'opacity-50' : ''}`}>{sending ? 'Aguarde...' : 'Enviar indicações'}</button>
                            )
                        }
                        {
                            isFull && (
                                <div className="text-red-700">Você já fez todas indicações.</div>
                            )
                        }
                    </div>
                </form>
            </div>
            <Modal
                title="Confirme suas indicações"
                visible={showConfirm}
                onOk={() => { setShowConfirm(false); handleSubmit(undefined, true); }}
                onCancel={() => setShowConfirm(false)}
                okText="Confirmar"
                cancelText="Voltar"
                width={300}
            >
                <p className="mb-5"><strong>Você preencheu:</strong></p>
                {data?.responsibility.map((responsibility: any, index: number) => {
                    const filtered = voting.filter((item) => item.responsibility === responsibility.id);
                    let count = 0;
                    formVotes[responsibility.id]?.forEach((item: any) => {
                        if (item) {
                            count++;
                        }
                    });
                    return (
                        <p key={index}>{filtered.length + count} de {responsibility.qty} nomes para {responsibility.name.toUpperCase()}</p>
                    )
                })}
            </Modal>
        </>
    )
}

export default RecommendationRecommendationPage;

