import React, {
  useContext,
  createContext,
  FunctionComponent,
  useState,
} from "react";
import { ELECTION_LOADING, ELECTION_LOGIN } from "../constants";
import { ElectionProps } from "./use-election.props";

const electionContext = createContext({} as ElectionProps);

export const useElection = (): ElectionProps => {
  return useContext(electionContext);
};

function useProvideElection(church: any, data: any): ElectionProps {
  // const [currentPage, setCurrentPage] = useState<string>(ELECTION_RESULT_COUNTING);
  // const [currentPage, setCurrentPage] = useState<string>(ELECTION_RESULT);
  // const [currentPage, setCurrentPage] = useState<string>(ELECTION_VOTING);
  // const [currentPage, setCurrentPage] = useState<string>(ELECTION_LOGIN);
  // const [currentPage, setCurrentPage] = useState<string>(ELECTION_LOADING);
  const [currentPage, setCurrentPage] = useState<string>(ELECTION_LOADING);
  // const [user, setUser] = useState<any>();
  const [titlePage, setTitlePage] = useState('');
  const [dataDeliberation, setDataDeliberation] = useState<any>(undefined);
  const [key, setKey] = useState<any>();
  return {
    church,
    data,
    // user, 
    currentPage,
    titlePage,
    key,
    dataDeliberation,
    // setUser,
    setCurrentPage,
    setTitlePage,
    setKey,
    setDataDeliberation
  };
}

export const ProvideElection: FunctionComponent<{ church: any, data: any }> = ({ church, data, children }) => {
  const value: ElectionProps = useProvideElection(church, data);
  return (
    <electionContext.Provider value={value}>
      {children}
    </electionContext.Provider>
  );
};
