import React from "react";
import { useRecommendation } from "../../../contexts/use-recommendation";

function RecommendationNoMemberPage() {
    const recommendationContext = useRecommendation();
    const { setCurrentPage } = recommendationContext;

    function handleNoMember() {
        setCurrentPage(1)
    }

    return (
        <>
            <div className="mb-8">
                <div className="my-5">
                    <p>A indicação é exclusiva para os membros.</p>
                    <p>Para receber orientações sobre sua admissão à membresia, fale com um dos pastores da Igreja.</p>
                </div>
            </div>
            <div className="text-center">
                <button type="button" className="bg-gray-700 transform hover:scale-105 transition duration-500 opacity-75 text-white py-3 px-8 rounded mr-4" onClick={handleNoMember}>Voltar</button>
            </div>
        </>
    )
}

export default RecommendationNoMemberPage;

