import DeliberationResultComponent from "components/deliberationResult/deliberationResult";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getDeliberationLinkToElectionSnapshot } from "services/church";
import { ELECTION_LOGIN_RECEIPT } from "../../../constants";
import { useElection } from "../../../contexts";

function ElectionResultPage() {
    const electionContext = useElection();
    const { setCurrentPage, setTitlePage, data } = electionContext;
    const [dataDeliberationLink, setDataDeliberationLink] = useState(undefined);

    useEffect(() => {
        setTitlePage('RESULTADO DAS ELEIÇÕES');
        const unsubscribe: any = getDeliberation();
        return () => {
            setTitlePage('');
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, []);

    function getDeliberation() {
        return getDeliberationLinkToElectionSnapshot(electionContext?.church?.id, data.id, (data) => {
            // faz a condição para não aparecer a msg de não consolidado
            if(!!data && !!data?.consolidated) {
                setDataDeliberationLink(data);
            }
        });
    }

    function renderResult() {
        return data?.responsibility?.map((item: any, index: number) => {
            const consolidated = data.consolidated[item.id];

            return (
                <div key={index} className="border pt-5 pr-5 pl-5 mb-6 border-primary-base">
                    <div className="text-center mb-5 space-y-1">
                        <p><strong>{item.pluralName.toUpperCase()}</strong></p>
                        <p>(A idade é critério para desempate.)</p>
                    </div>
                    {/* <Votantes qty={consolidated.quorum} /> */}
                    <div className="flex flex-wrap sm:divide-x sm:divide-gray-700">
                        <div className="w-full sm:flex-1">
                            <p>Votos Totais: <strong>{consolidated.total}</strong></p>
                            <p>Votos Brancos: <strong>{consolidated.whiteVote}</strong></p>
                            <p>Votos Nulos: <strong>{consolidated.nullVote}</strong></p>
                            <p>Votos Válidos*: <strong>{consolidated.validMajority}</strong></p>
                            {/* <p>Maioria Simples: <strong>{consolidated.simple}</strong></p> */}
                        </div>
                        <div className="w-full sm:flex-1 sm:pl-5">
                            <p>Vagas: <strong>{consolidated.qty}</strong></p>
                            {
                                data.countingType === 'coefficient' && (
                                    <>
                                        <p>Coeficiente eleitoral**: <strong>{consolidated.coefficient}</strong></p>
                                        <p>Maioria Válida: <strong>{consolidated.valid}</strong></p>
                                    </>
                                )
                            }
                            <p>Eleitos: <strong>{consolidated?.votes?.filter(({ isElected }: any) => isElected)?.length || 0}</strong></p>
                        </div>

                    </div>
                    <div className="flex flex-wrap sm:divide-x sm:divide-gray-700 mb-6">
                        <div className="w-full sm:flex-1">
                            <small>*Descontados brancos e nulos</small>
                        </div>
                        {
                            data.countingType === 'coefficient' && (
                                <div className="w-full sm:flex-1  sm:pl-5">
                                    <small>**Votos válidos / vagas</small>
                                </div>
                            )
                        }
                    </div>
                    {
                        consolidated.votes.map((vote: any, index: number) => (
                            <div key={`votes-${index}`} className="mb-5">
                                <p><strong>{vote.position}º - {vote.name}</strong></p>
                                <div className="pl-6">
                                    <p>
                                        <span className="mr-2">{vote.qtyVotes} Votos</span>
                                        {
                                            vote.isElected
                                                ? <strong className="text-green-600">ELEITO</strong>
                                                : <span className="text-gray-500">NÃO ELEITO</span>
                                        }

                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            )
        })
    }

    if (!data.consolidated || !data.result) {
        return (
            <div>Resultado não consolidado</div>
        );
    }

    const quorum = data.consolidated[data?.responsibility[0]?.id]?.quorum;
    const age = quorum >= data?.minimumQuorum;

    return (
        <>
            <div className="mb-5 -mt-5">
                <div className="text-center">
                    <p><strong>{moment(data?.start?.seconds * 1000).format('DD/MM/YYYY')}</strong></p>
                </div>
                <div className="flex flex-wrap sm:divide-x sm:divide-gray-700 mt-4">
                    <div className="w-full sm:flex-1">
                        <p>Membros Comungantes: <strong>{data?.listOfMembers}</strong></p>
                        <p>Quórum mínimo: <strong>{data?.minimumQuorum}</strong></p>
                    </div>
                    <div className="w-full sm:flex-1 sm:pl-5">
                        <p>Votantes: <strong>{data.consolidated[data?.responsibility[0]?.id]?.quorum}</strong></p>
                        <p className={`${!age ? 'text-red-600' : ''}`}><strong>A.G.E. {!age ? 'NÃO' : ""} INSTALADA</strong></p>
                    </div>
                </div>
            </div>
            {age && renderResult()}

            {/* <div className="border pt-5 pr-5 pl-5 mb-6 border-primary-base">
                <p className="mb-5"><strong>RESULTADO PARA PRESBÍTEROS</strong></p>
                <p className="mb-5">Votante: 544</p>
                <div className="flex divide-x divide-gray-700 mb-6">
                    <div className="flex-1">
                        <p>Votos Totais: 4352</p>
                        <p>Votos Brancos: 791</p>
                        <p>Votos Nulos: 16</p>
                        <p>Maioria Simples: 273</p>
                    </div>
                    <div className="flex-1 pl-5">
                        <p>Votos Válidos: <strong>4352</strong></p>
                        <p>Vagas: <strong>791</strong></p>
                        <p>Coeficiente eleitoral: <strong>444</strong></p>
                        <p>Maioria Válida: <strong>223</strong></p>
                    </div>
                </div>
                <div className="mb-5">
                    <p><strong>1º - André Stephen Ribeiro dos Anjos</strong></p>
                    <div className="pl-6"><p>428 Votos <strong className="text-green-600">ELEITO</strong></p></div>
                </div>
                <div className="mb-5">
                    <p><strong>2º - André Stephen Ribeiro dos Anjos</strong></p>
                    <div className="pl-6"><p>428 Votos <strong className="text-green-600">ELEITO</strong></p></div>
                </div>
                <div className="mb-5">
                    <p><strong>3º - André Stephen Ribeiro dos Anjos</strong></p>
                    <div className="pl-6"><p>428 Votos <strong className="text-green-600">ELEITO</strong></p></div>
                </div>
                <div className="mb-5">
                    <p><strong>4º - André Stephen Ribeiro dos Anjos</strong></p>
                    <div className="pl-6"><p>428 Votos <strong className="text-green-600">ELEITO</strong></p></div>
                </div>
                <div className="mb-5">
                    <p><strong>5º - André Stephen Ribeiro dos Anjos</strong></p>
                    <div className="pl-6"><p>428 Votos <strong className="text-green-600">ELEITO</strong></p></div>
                </div>
                <div className="mb-5">
                    <p><strong>6º - André Stephen Ribeiro dos Anjos</strong></p>
                    <div className="pl-6"><p>428 Votos <span className="text-gray-300">NÃO ELEITO</span></p></div>
                </div>
            </div> */}
            {
                !!dataDeliberationLink && (
                    <DeliberationResultComponent data={dataDeliberationLink} electionData={data} />
                )
            }
            <div className="text-center">
                <button
                    type="button"
                    className="text-base text-primary-base font-bold transform hover:scale-105 transition duration-500 py-3 rounded focus:outline-none underline"
                    onClick={() => setCurrentPage(ELECTION_LOGIN_RECEIPT)}
                >
                    Meu comprovante
                </button>
            </div>
        </>
    )
}

export default ElectionResultPage;

