import React from "react";
import { ProvideRecommendation } from "../../../contexts/use-recommendation";
import RecommendationBasePage from "./base";

function RecommendationPage({church, data}: any) {
    return (
        <ProvideRecommendation church={church} data={data}>
            <RecommendationBasePage />
        </ProvideRecommendation>
    )
}

export default RecommendationPage;

