import DeliberationVoteComponent from "components/deliberationVote/deliberationVote";
import { useElection } from "contexts";
import React from "react";
import { DELIBERATION_RECEIPT } from "../../../constants";

function ElectionVotingDeliberationPage() {
    const electionContext = useElection();
    const { key, dataDeliberation, setCurrentPage, setTitlePage } = electionContext;
    
    function nextPage() {
        setCurrentPage(DELIBERATION_RECEIPT);
    }
    
    return (
        <DeliberationVoteComponent
            deliberations={dataDeliberation?.deliberations}
            electionKey={key}
            setTitlePage={setTitlePage}
            nextPage={nextPage}
        />
    )

}

export default ElectionVotingDeliberationPage;

