import React, {
  useContext,
  createContext,
  FunctionComponent,
  useState,
} from "react";
import { RecommendationProps } from "./use-recommendation.props";

enum Pages {
  "noMember" = 0, // quando clica que não é um membro
  "home" = 1, // página inicial
  "isMember" = 2, // página inicial
  "login" = 3, // página de email/telefone
  "recommendations" = 4, // página de recomendações
  "feedbackSubmit" = 5, // página de feedback após enviar suas indicações
  "feedbackFullRecommendation" = 6 // página de quando já foram feitas todas indicações e ele tenta fazer uma nova indicação
}

const recommendationContext = createContext({} as RecommendationProps);

export const useRecommendation = (): RecommendationProps => {
  return useContext(recommendationContext);
};

function useProvideRecommendation(church: any, data: any): RecommendationProps {
  const [currentPage, setCurrentPage] = useState(1);
  const [user, setUser] = useState<any>();
  return {
    church,
    data,
    user, 
    pages: Pages,
    currentPage: Pages[currentPage],
    setUser,
    setCurrentPage,
  };
}

export const ProvideRecommendation: FunctionComponent<{church: any, data: any}> = ({ church, data, children }) => {
  const value: RecommendationProps = useProvideRecommendation(church, data);
  return (
    <recommendationContext.Provider value={value}>
      {children}
    </recommendationContext.Provider>
  );
};
