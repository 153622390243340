import React, { useState, useEffect } from "react";
import SplashPage from "../Splash";
import { useParams, useHistory } from "react-router-dom";
import { COLLECTION_CHURCH, COLLECTION_CHURCH_DELIBERATION, COLLECTION_CHURCH_ELECTION, COLLECTION_CHURCH_RECOMMENDATION } from "../../constants";
import RecommendationPage from "./recommendation";
import { churchGetDataSnapshot, churchGetElectionOrDeliberationSnapshot, churchGetRecommendationSnapshot } from "../../services";
import ElectionPage from "./election";
import { useLayout } from "contexts";
import DeliberationPage from "./deliberation";

function ChurchPage() {
    const history = useHistory();
    const { churchId }: any = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingRecommendation, setLoadingRecommendation] = useState(true);
    const [loadingElection, setLoadingElection] = useState(true);
    const [loadingDeliberation, setLoadingDeliberation] = useState(true);
    const [data, setData] = useState<any>();
    const [dataRecommendation, setDataRecommendation] = useState<any>();
    const [dataElection, setDataElection] = useState<any>();
    const [dataDeliberation, setDataDeliberation] = useState<any>();

    const { selectedPage } = useLayout();

    function hasRecommendation(doc: string) {
        setLoadingRecommendation(true);
        return churchGetRecommendationSnapshot(doc, (resp) => {
            setDataRecommendation(resp);
            setLoadingRecommendation(false);
        });
    }

    function hasElection(doc: string) {
        setLoadingElection(true);
        return churchGetElectionOrDeliberationSnapshot(doc,
            (resp) => {
                setDataElection(resp);
                setLoadingElection(false);
            }
        );
    }

    function hasDeliberation(doc: string) {
        setLoadingDeliberation(true);
        return churchGetElectionOrDeliberationSnapshot(doc,
            (resp) => {
                setDataDeliberation(resp);
                setLoadingDeliberation(false);
            }
        );
    }

    function getChurch() {
        return churchGetDataSnapshot(churchId, (data: any) => {
            if (!data) {
                history.push('/igreja-inexistente');
                return;
            }

            setData(data);
            setLoading(false);
        })
    }

    useEffect(() => {
        const unsubscribe: any = getChurch();
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, []);

    useEffect(() => {
        if (data) {
            const unsubscribeRecommendation: any = hasRecommendation(`${COLLECTION_CHURCH}/${data.id}/${COLLECTION_CHURCH_RECOMMENDATION}`);
            const unsubscribeElection: any = hasElection(`${COLLECTION_CHURCH}/${data.id}/${COLLECTION_CHURCH_ELECTION}`);
            const unsubscribeDeliberation: any = hasDeliberation(`${COLLECTION_CHURCH}/${data.id}/${COLLECTION_CHURCH_DELIBERATION}`);
            return () => {
                if (unsubscribeRecommendation) {
                    unsubscribeRecommendation();
                }
                if (unsubscribeElection) {
                    unsubscribeElection();
                }
                if (unsubscribeDeliberation) {
                    unsubscribeDeliberation();
                }
            }
        }
    }, [data]);

    if (loading || loadingRecommendation || loadingElection || loadingDeliberation) {
        return (
            <SplashPage />
        )
    }

    const style = {
        "--primary-base": data?.color,
        "--primary-contrast": data['color-contrast']
    } as React.CSSProperties;
    return (
        <>
            <div className="flex-1 flex flex-col bg-primary-base" style={style}>
                <div className="max-w-lg w-full mx-auto">
                    <div className="container mx-3 sm:mx-auto">
                        <div className="py-4 flex items-center logo-rounded relative">
                            <div className="relative flex items-center">
                                <img className="w-48 mr-5" src={data?.logo} alt={data?.name} />
                                {/* <h1 className="text-gray-800"><strong>Sistema de <br />Eleições <br />{data?.name}</strong></h1> */}
                            </div>
                        </div>
                    </div>
                    <div className="container mx-auto text-primary-base text-sm">
                        {(dataRecommendation && (!selectedPage || selectedPage === 'recommendation')) && <RecommendationPage church={data} data={dataRecommendation} />}
                        {(dataElection && (!selectedPage || selectedPage === 'election')) && <ElectionPage church={data} data={dataElection} />}
                        {(dataDeliberation && (!selectedPage || selectedPage === 'deliberation')) && <DeliberationPage church={data} data={dataDeliberation} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChurchPage;