import React, { FC, useEffect, useState } from "react";

const Voting: FC<any> = ({ data, onChange }) => {
    const [isFull, setIsFull] = useState(false);
    const [selected, setSelected] = useState<Array<String>>([]);

    useEffect(() => {
        setIsFull(selected.length >= data.qty);
    }, [selected]);

    function handleAdd(id: any) {
        if (!isFull) {
            const newArr = [...selected];
            newArr.push(id);
            setSelected(newArr);
            onChange(newArr);
        }
    }

    function handleRemove(id: any) {
        const newArr = [...selected];
        const index = newArr.findIndex((fId) => fId === id);
        newArr.splice(index, 1);
        setSelected(newArr);
        onChange(newArr);
    }

    function renderCard() {
        const person = [...data?.person].sort(function (a: any, b: any) {
            return a.name.localeCompare(b.name);
        });
        return person.map((item: any, index: any) => {
            const isSelected = selected.includes(item.id);
            if (!item.disabled) {
                return (
                    <div key={item.id} className={`border border-primary-base p-3 mb-5 ${isSelected ? 'election-card-selected' : ''} ${isFull && !isSelected ? 'opacity-25' : ''}`}>
                        <div className="flex items-start mb-5">
                            <div className="w-2/5 bg-gray-400">
                                {item.photo && <img className="object-cover h-32 sm:h-48 w-full" loading="lazy" src={item.photo} alt="" />}
                            </div>
                            <div className="w-3/5 flex-1 pl-3">
                                <p className="mb-3 text-base"><strong>{item.name}</strong></p>
                                <p>
                                    {item.description}
                                </p>
                            </div>
                        </div>
                        <button
                            type="button"
                            className={`w-full transform focus:outline-none transition duration-500 py-4 px-10 rounded ${isFull && !isSelected ? 'cursor-not-allowed' : ''} ${isSelected ? 'bg-primary-contrast text-primary-base' : 'hover:scale-102 bg-primary-base text-primary-contrast'}`}
                            onClick={() => { isSelected ? handleRemove(item.id) : handleAdd(item.id) }}
                        >
                            {isSelected ? 'Desmarcar' : 'Selecionar'}
                        </button>
                    </div>
                )
            }
            return null;
        })
    }

    return (
        <div key={data.id}>
            <p className="mb-5">Para votar, selecione até <strong>{data.qty}</strong> candidato(s) e clique no botão <strong>VOTAR</strong> no final da tela.</p>
            {renderCard()}
        </div>
    )
}

export default Voting;