import { notification, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DELIBERATION_LOGIN } from "../../../constants";
import { useDeliberation } from "../../../contexts";
import { churchGetKeyDeliberationGet } from "../../../services";

function DeliberationReceiptPage() {
    const deliberationContext = useDeliberation();
    const [loading, setLoading] = useState(false);
    const { setTitlePage, setCurrentPage, data, key } = deliberationContext;
    const { deliberations } = data;

    useEffect(() => {
        setTitlePage('COMPROVANTE DE VOTAÇÃO');
        getDataKey();
        return () => {
            deliberationContext.setKey(undefined);
            setTitlePage('');
        }
    }, []);

    async function getDataKey() {
        setLoading(true);

        try {
            const resp = await churchGetKeyDeliberationGet(deliberationContext?.church?.id, deliberationContext?.data?.id, key.key.toLocaleLowerCase());

            if (resp.empty) {
                return;
            }

            let data$: any;
            resp.forEach((doc) => {
                data$ = {
                    ...doc.data(),
                    ...{ id: doc.id },
                };
            });

            deliberationContext.setKey(data$);
        } catch (error) {
            notification.error({ message: 'Algo deu errado. Tente novamente.' });
        } finally {
            setLoading(false);
        }
    }

    function renderDate() {
        return deliberations?.map(({ id, name }: any) => {
            return (
                <p key={id}>
                    Votação para <strong>{name?.toUpperCase()}</strong>: {
                        key?.votedDeliberation && key?.votedDeliberation[id] 
                        ? moment((key?.votedDeliberation[id]?.createdAt?.seconds * 1000)).format("DD/MM/YYYY [às] HH:mm")
                        : "Voto(s) não realizados"
                    }
                </p>
            )
        })
    }

    return (
        <div className="text-center">
            <div>
                <p>Votação realizada com sucesso!</p>
                <p className="text-6xl">
                    <i className="uil uil-check-circle"></i>
                </p>
            </div>
            <div className="mb-5">
                <p><strong>Mostre esse comprovante ao mesário para encerrar sua participação.</strong></p>
                {/* <p>Veja abaixo o comprovante da sua votação:</p> */}
            </div>
            {
                loading
                    ? <Spin size="large" />
                    : renderDate()
            }

            <div className="mt-6">
                <button
                    type="button"
                    className={`bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded`}
                    onClick={() => setCurrentPage(DELIBERATION_LOGIN)}
                >
                    Início
                </button>
            </div>
        </div>
    )
}

export default DeliberationReceiptPage;

