import { notification } from "antd";
import React, { useState } from "react";
import { DELIBERATION_INVALID_PASSWORD, DELIBERATION_LOGIN_RECEIPT, DELIBERATION_RECEIPT, DELIBERATION_VOTING } from "../../../constants";
import { useDeliberation } from "../../../contexts";
import { churchGetKeyDeliberationGet } from "../../../services";
import { messageError } from "../../../utils";
const validate = require("validate.js");

function DeliberationLoginPage() {
    const characters = 6;
    const deliberationContext = useDeliberation();
    const { data, setCurrentPage } = deliberationContext;
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [erros, setErros] = useState<any>();
    const [form, setForm] = useState({
        key: '',
    });
    const { deliberations } = data;


    function validateForm(form: any) {
        const constraints = {
            key: {
                presence: {
                    allowEmpty: false,
                    message: messageError('Informar', ' a senha')
                },
                length: {
                    minimum: characters,
                    tooShort: messageError('A senha', `deve ter ${characters} caracteres.`) // %{count}
                }
            }
        };

        // validate.validators.type.types.cpf = function (value: string) { return isValidCPF(value); };

        const v = validate({ ...form }, constraints);
        setErros(v);
        return v;
    }

    async function handleSubmit(e: any) {
        e.preventDefault();
        const v = validateForm(form);
        setIsSubmitted(true);
        if (!v) {
            setLoading(true);

            try {
                const resp = await churchGetKeyDeliberationGet(deliberationContext?.church?.id, deliberationContext?.data?.id, form.key.toLocaleLowerCase());

                if (resp.empty) {
                    setCurrentPage(DELIBERATION_INVALID_PASSWORD);
                    return;
                }

                let data$: any;
                resp.forEach((doc) => {
                    data$ = {
                        ...doc.data(),
                        ...{ id: doc.id },
                    };
                });

                deliberationContext.setKey(data$);
                const withoutVotes: boolean[] = [];
                deliberations?.map(({ id }: any) => {
                    withoutVotes.push(!!(data$?.votedDeliberation && data$?.votedDeliberation[id]))
                })

                if (withoutVotes.includes(false)) {
                    setCurrentPage(DELIBERATION_VOTING)
                } else {
                    setCurrentPage(DELIBERATION_RECEIPT);
                }
            } catch (error) {
                notification.error({ message: 'Algo deu errado. Tente novamente.' });
            } finally {
                setLoading(false);
            }
        }
    }

    function handleFormChange(value: { [key: string]: any }) {
        const newForm = {
            ...form,
            ...value
        }
        setForm(newForm);
        if (isSubmitted) {
            validateForm(newForm);
        }
    }

    if(data?.result) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col">
            <div className="mb-5">
                <p>Para iniciar, digite abaixo a senha que você recebeu do mesário.</p>
            </div>
            <div className="mb-8">
                <input
                    className={`text-center text-md sm:text-xl bg-gray-200 h-20 focus:outline-none py-2 px-4 block w-full appearance-none leading-normal placeholder-primary-base border ${erros?.key ? 'border-red-500' : 'border-gray-300'}`}
                    type="text"
                    placeholder="Digite aqui a senha de votação"
                    value={form.key}
                    maxLength={characters}
                    onChange={(e: any) => handleFormChange({ key: e.target.value.toUpperCase() })}
                />
                {erros?.key && <p className="text-red-500 text-xs italic mt-1">{erros?.key.join('. ')}</p>}
            </div>
            <div className="flex flex-col justify-center">
                <div className="text-center mb-5">
                    <button
                        type="submit"
                        disabled={loading}
                        className={`bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded ${loading ? 'opacity-50' : ''}`}
                    >
                        {loading ? 'Aguarde...' : 'Iniciar'}
                    </button>
                </div>
                <div className="text-center">
                    <button
                        type="button"

                        className={`text-primary-base font-bold transform hover:scale-105 transition duration-500 py-3 rounded focus:outline-none underline`}
                        onClick={() => setCurrentPage(DELIBERATION_LOGIN_RECEIPT)}
                    >
                        Meu comprovante
                    </button>
                </div>
            </div>
        </form>
    )
}

export default DeliberationLoginPage;

