import React from "react";
import { ELECTION_LOGIN } from "../../../constants";
import { useElection } from "../../../contexts";

function ElectionInvalidPasswordPage() {
    const electionContext = useElection();
    const { setCurrentPage } = electionContext;

    return (
        <div className="text-center">
            <div className="mb-5">
                <p>Senha de votação inválida. Tenha em mãos a senha recebida do mesário e tente informá-la novamente.</p>
            </div>
            <div className="text-center">
                <button
                    type="button"
                    className={`bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded`}
                    onClick={() => setCurrentPage(ELECTION_LOGIN)}
                >
                    Início
                </button>
            </div>
        </div>
    )
}

export default ElectionInvalidPasswordPage;

