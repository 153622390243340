import { ProvideDeliberation } from "contexts";
import React from "react";
import DeliberationBasePage from "./base";
// import { ProvideRecommendation } from "../../../contexts/use-recommendation";

function DeliberationPage({church, data}: any) {
    return (
        <ProvideDeliberation church={church} data={data}>
            <DeliberationBasePage />
        </ProvideDeliberation>
    )
}

export default DeliberationPage;

