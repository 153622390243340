import firebase from "firebase/app";
import { COLLECTION_CHURCH, COLLECTION_CHURCH_DELIBERATION, COLLECTION_KEYS } from "../constants";

export function churchGetDataSnapshot(
  churchId: string,
  callback: (arr: any) => void
) {
  return firebase
    .firestore()
    .collection(COLLECTION_CHURCH)
    .where("slug", "==", churchId)
    .limit(1)
    .onSnapshot((snapshot) => {
      if (snapshot.empty) {
        callback(undefined);
        return;
      }

      snapshot.forEach((doc) => {
        const data$ = {
          ...doc.data(),
          ...{ id: doc.id },
        };
        callback(data$);
      });
    });
}
export function getDeliberationLinkToElectionSnapshot(
  churchId: string,
  electionId: string,
  callback: (arr: any) => void
) {
  return firebase
    .firestore()
    .collection(`${COLLECTION_CHURCH}/${churchId}/${COLLECTION_CHURCH_DELIBERATION}`)
    .where("electionLink", "==", electionId)
    .limit(1)
    .onSnapshot((snapshot) => {
      if (snapshot.empty) {
        callback(undefined);
        return;
      }

      snapshot.forEach((doc) => {
        const data$ = {
          ...doc.data(),
          ...{ id: doc.id },
        };
        callback(data$);
      });
    });
}

export function churchGetRecommendationSnapshot(
  docURL: string,
  callback: (arr: any) => void
) {
  const now = firebase.firestore.Timestamp.now();
  return firebase
    .firestore()
    .collection(docURL)
    .where("endShow", ">=", now)
    .onSnapshot((resp) => {
      if (resp.empty) {
        callback(undefined);
        return;
      }
      resp?.forEach((item: any) => {
        const data$ = {
          ...item.data(),
          ...{ id: item.id },
        };
        // no firebase não da para fazer  between entre datas de fields diferentes
        // .where('start', '<=', now) .where('end', '>=', now)
        if (data$.startShow.seconds <= now.seconds) {
          callback(data$);
          return;
          // setDataRecommendation(data$);
        }
      });
    });
}

export function churchGetElectionOrDeliberationSnapshot(
  docURL: string,
  callback: (arr: any) => void
) {
  const now = firebase.firestore.Timestamp.fromDate(new Date());
  return firebase
    .firestore()
    .collection(docURL)
    .where("active", "==", true)
    .where("endShow", ">=", now)
    // .where("type", )
    .onSnapshot((resp) => {
      if (resp.empty) {
        callback(undefined);
        return;
      }

      let respData: any = undefined;

      resp?.forEach((item: any) => {
        const data$ = {
          ...item.data(),
          ...{ id: item.id },
        };
        // no firebase não da para fazer  between entre datas de fields diferentes
        // .where('start', '<=', now) .where('end', '>=', now)

        if (data$?.start?.seconds <= now?.seconds) {
          respData = data$;
        }
      });

      callback(respData);
    });
}

export async function getDeliberationLinkToElection(
  churchId: string,
  electionId: string,
) {
  return firebase
    .firestore()
    .collection(`${COLLECTION_CHURCH}/${churchId}/${COLLECTION_CHURCH_DELIBERATION}`)
    .where("electionLink", "==", electionId)
    .limit(1)
    .get();
}

// export function churchGetKeyElectionSnapshot(
//   key: string,
//   callback: (arr: any) => void
// ) {
//   return firebase
//     .firestore()
//     .collection(COLLECTION_KEYS)
//     .where("key", "==", key)
//     .limit(1)
//     .onSnapshot((snapshot) => {
//       if (snapshot.empty) {
//         callback(undefined);
//         return;
//       }

//       snapshot.forEach((doc) => {
//         const data$ = {
//           ...doc.data(),
//           ...{ id: doc.id },
//         };
//         callback(data$);
//       });
//     });
// }
export async function churchGetKeyElectionGet(
      churchId: string,
      electionId: string,
      key: string,
    ) {
    return firebase
      .firestore()
      .collection(COLLECTION_KEYS)
      .where("churchId", "==", churchId)
      .where("electionId", "==", electionId)
      .where("key", "==", key)
      .limit(1)
      .get();
  }

  export async function churchGetKeyDeliberationGet(
    churchId: string,
    deliberationId: string,
    key: string,
  ) {
    return firebase
      .firestore()
      .collection(COLLECTION_KEYS)
      .where("churchId", "==", churchId)
      .where("deliberationId", "==", deliberationId)
      .where("key", "==", key)
      .limit(1)
      .get();
  }

  export function churchSaveVoteElectionSnapshot(
    idKey: string,
    idResponsability: string,
    votes: Array<string>
  ) {
    const now = firebase.firestore.Timestamp.fromDate(new Date());

    const data = {
      voted: {
        [idResponsability]: {
          votes,
          createdAt: now,
        },
      }
    };

    return firebase
      .firestore()
      .collection(COLLECTION_KEYS)
      .doc(idKey)
      .set(data, { merge: true });
  }

  export function churchSaveVoteDeliberationSnapshot(
    idKey: string,
    idDeliberation: string,
    vote: 'AFAVOR' | 'CONTRA' | 'BRANCO'
  ) {
    const now = firebase.firestore.Timestamp.fromDate(new Date());

    const data = {
      votedDeliberation: {
        [idDeliberation]: {
          vote,
          createdAt: now,
        },
      }
    };

    return firebase
      .firestore()
      .collection(COLLECTION_KEYS)
      .doc(idKey)
      .set(data, { merge: true });
  }
