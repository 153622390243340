import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import SplashPage from "../pages/Splash";
import ChurchPage from "../pages/Church";
import { useFirebase } from "../contexts";
import NotChurchPage from "../pages/NotChurch";

export default function Routes() {
  const firebaseContext = useFirebase();

  const { loading } = firebaseContext;

  if (loading) {
    return <SplashPage />;
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/igreja-inexistente" />
        </Route>
        <Route exact path="/igreja-inexistente" component={NotChurchPage} />
        <Route path="/:churchId" component={ChurchPage} />
      </Switch>
    </Router>
  );
}