import React, {
  useContext,
  createContext,
  FunctionComponent,
  useState,
} from "react";
import { DELIBERATION_LOADING, DELIBERATION_LOGIN } from "../constants";
import { DeliberationProps } from "./use-deliberation.props";

const deliberationContext = createContext({} as DeliberationProps);

export const useDeliberation = (): DeliberationProps => {
  return useContext(deliberationContext);
};

function useProvideDeliberation(church: any, data: any): DeliberationProps {
  // const [currentPage, setCurrentPage] = useState<string>(DELIBERATION_RESULT_COUNTING);
  // const [currentPage, setCurrentPage] = useState<string>(DELIBERATION_RESULT);
  // const [currentPage, setCurrentPage] = useState<string>(DELIBERATION_VOTING);
  // const [currentPage, setCurrentPage] = useState<string>(DELIBERATION_LOGIN);
  // const [currentPage, setCurrentPage] = useState<string>(DELIBERATION_LOADING);
  const [currentPage, setCurrentPage] = useState<string>(DELIBERATION_LOADING);
  // const [user, setUser] = useState<any>();
  const [titlePage, setTitlePage] = useState('');
  const [key, setKey] = useState<any>();
  return {
    church,
    data,
    // user, 
    currentPage,
    titlePage,
    key,
    // setUser,
    setCurrentPage,
    setTitlePage,
    setKey,
  };
}

export const ProvideDeliberation: FunctionComponent<{church: any, data: any}> = ({ church, data, children }) => {
  const value: DeliberationProps = useProvideDeliberation(church, data);
  return (
    <deliberationContext.Provider value={value}>
      {children}
    </deliberationContext.Provider>
  );
};
