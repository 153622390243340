import React from "react";
import { useRecommendation } from "../../../contexts/use-recommendation";
import moment from "moment";
import firebase from "firebase/app";
import { useLayout } from "contexts";

function RecommendationHomePage() {
    const recommendationContext = useRecommendation();
    const { setSelectedPage } = useLayout();
    const { data } = recommendationContext;
    const { responsibility: responsibilities } = data;
    const now = firebase.firestore.Timestamp.fromDate(new Date());

    function handleLogin() {
        recommendationContext.setCurrentPage(2);
    }

    function handleClose() {
        setSelectedPage(undefined);
        recommendationContext.setCurrentPage(7);
    }

    function renderButtonStart() {
        if (data.start.seconds <= now.seconds && data.end.seconds >= now.seconds) { // Iniciadas
            return (
                <button type="button" className="mt-6 bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded" onClick={handleLogin}>
                    Iniciar indicações
                </button>
            )
        } else if (data.startShow.seconds <= now.seconds && data.end.seconds >= now.seconds) { // tem o startShow mas ainda não iniciou
            return (
                <button type="button" disabled className="mt-6 bg-primary-base text-primary-contrast py-3 px-10 rounded opacity-25 cursor-not-allowed">
                    Indicações não iniciadas
                </button>
            )
        } else { // Encerradas
            return (
                <button type="button" disabled className="mt-6 bg-primary-base text-primary-contrast py-3 px-10 rounded opacity-25 cursor-not-allowed">
                    Indicações encerradas
                </button>
            )
        }
    }

    return (
        <div>
            <div className="mb-8">
                <div className="mb-4">
                    <div className="my-5">
                        <p className="mb-3">
                            A eleição de oficiais da Igreja ocorrerá em <strong>{moment((data?.election?.seconds * 1000)).format("DD/MM/YYYY")}</strong> (em primeira convocação) {data?.election2?.seconds && <span>ou em <strong>{moment((data?.election2?.seconds * 1000)).format("DD/MM/YYYY")}</strong> (em segunda convocação)</span>} .
                        </p>
                        <p className="mb-3">
                            Ofícios: {responsibilities.map((responsibility: any, index: number) => {
                                let prefix = '';
                                if (index > 0) {
                                    prefix = ', ';
                                    if (responsibilities.length === index + 1) {
                                        prefix = ' e ';
                                    }
                                }
                                return `${prefix}${responsibility.name}`
                            })}.<br />
                            Período das indicações: <strong>{moment((data?.start?.seconds * 1000)).format("DD/MM/YYYY")}</strong> até <strong>{moment((data?.end?.seconds * 1000)).format("DD/MM/YYYY")}</strong>.
                        </p>
                        <p className="mb-3">
                            Para mais informações sobre a eleição pela Assembleia Geral Extraordinária, acesse o link abaixo:
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <div className="text-center">
                    <a href={data.term} target="_blank" rel="noopener noreferrer" className="underline text-lg hover:text-primary-base hover:underline">
                        <strong>Ler o Edital de Convocação</strong>
                    </a>
                </div>
                {renderButtonStart()}
            </div>
        </div>
    )
}

export default RecommendationHomePage;

