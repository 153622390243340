import React from "react";

function NotChurchPage() {
    return (
        <div className="min-h-screen min-w-full flex justify-center items-center">
            <h3 className="text-red-800 text-2xl">Igreja Inválida</h3>
        </div>
    )
}

export default NotChurchPage;