import firebase from "firebase/app";
import React, { useEffect, useRef, useState } from "react";
import ContainerTransition from "../../../components/container";
import { ELECTION_INVALID_PASSWORD, ELECTION_LOADING, ELECTION_LOGIN, ELECTION_LOGIN_RECEIPT, ELECTION_RECEIPT, ELECTION_RESULT, ELECTION_RESULT_COUNTING, ELECTION_VOTING, ELECTION_VOTING_DELIBERATION } from "../../../constants";
import { useElection } from "../../../contexts/use-election";
import ElectionInvalidPasswordPage from "./invalidPassword";
import ElectionLoginPage from "./login";
import ElectionLoginReceiptPage from "./loginReceipt";
import ElectionReceiptPage from "./receipt";
import ElectionResultPage from "./result";
import ElectionResultCountingPage from "./resultCounting";
import ElectionVotingPage from "./voting";
import ElectionVotingDeliberationPage from "./voting_deliberation";

function ElectionBasePage() {
    const electionContext = useElection();
    const { currentPage, setCurrentPage, titlePage, data } = electionContext;
    const [delayInterval, setDelayInterval] = useState<number | null>(null);

    useEffect(() => {
        setDelayInterval(1000);
        return () => {
            setDelayInterval(null);
        }
    }, []);

    useInterval(() => {
        const t = firebase.firestore.Timestamp.now().seconds;
        const ended = t > data.end.seconds;
        // console.log('aki', ended, data.result);
        let page: any;
        if (ended && data.result) {
            if (currentPage !== ELECTION_LOGIN_RECEIPT && currentPage !== ELECTION_INVALID_PASSWORD && currentPage !== ELECTION_RECEIPT && currentPage !== ELECTION_RESULT) {
                page = ELECTION_RESULT;
            }
            // } else if (data.apurando && !data.result) {
        } else if (ended && !data.result) {
            page = ELECTION_RESULT_COUNTING;
            // setDelayInterval(null);
            // alert('Votação encerrada!');
            // window.location.reload();
        }

        if (!page) {
            if (currentPage === ELECTION_LOADING) {
                setCurrentPage(ELECTION_LOGIN);
            }
        } else {
            setCurrentPage(page);
        }
    }, delayInterval);

    function useInterval(callback: () => void, delay: number | null) {
        const savedCallback: any = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    function renderSwitch() {
        switch (currentPage) {
            case ELECTION_LOGIN:
                return <ElectionLoginPage />;
            case ELECTION_LOGIN_RECEIPT:
                return <ElectionLoginReceiptPage />;
            case ELECTION_INVALID_PASSWORD:
                return <ElectionInvalidPasswordPage />;
            case ELECTION_RECEIPT:
                return <ElectionReceiptPage />;
            case ELECTION_RESULT:
                return <ElectionResultPage />;
            case ELECTION_RESULT_COUNTING:
                return <ElectionResultCountingPage />;
            case ELECTION_VOTING:
                return <ElectionVotingPage />;
            case ELECTION_VOTING_DELIBERATION:
                return <ElectionVotingDeliberationPage />;
            case ELECTION_LOADING:
                return 'Carregando...';
            default:
                return 'Nenhuma página foi encontrada';
        }
    }

    if (currentPage === ELECTION_LOADING) {
        return null;
    }

    return (
        <ContainerTransition currentPage={currentPage}>
            <p className={`font-bold text-lg mb-5 ${titlePage ? 'text-center' : ''}`}>{titlePage ? titlePage : 'Eleição de Oficiais'}</p>
            {renderSwitch()}
        </ContainerTransition>
    )
}

export default ElectionBasePage;

