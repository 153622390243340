import React from "react";
import { ProvideElection } from "../../../contexts/use-election";
// import { ProvideRecommendation } from "../../../contexts/use-recommendation";
import ElectionBasePage from "./base";

function ElectionPage({church, data}: any) {
    return (
        <ProvideElection church={church} data={data}>
            <ElectionBasePage />
        </ProvideElection>
    )
}

export default ElectionPage;

