import React from "react";
import { useElection } from "../../../contexts";

function ElectionResultCountingPage() {
    const electionContext = useElection();
    const { setCurrentPage } = electionContext;
    return (
        <div className="text-center">
            <div className="mb-5">
                <p>Votações encerradas.</p>
                <p>Em breve será divulgado o resultado.</p>
            </div>
            {/* <div className="text-center">
                <button
                    type="button"
                    className={`bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded`}
                    onClick={() => setCurrentPage(ELECTION_LOGIN)}
                >
                    Início
                </button>
            </div> */}
        </div>
    )
}

export default ElectionResultCountingPage;

