import React, { useState } from "react";
import { ELECTION_INVALID_PASSWORD, ELECTION_LOGIN, ELECTION_RECEIPT } from "../../../constants";
import { useElection } from "../../../contexts";
import { churchGetKeyElectionGet, getDeliberationLinkToElection } from "../../../services";
import { messageError } from "../../../utils";
const validate = require("validate.js");

function ElectionLoginReceiptPage() {
    const characters = 6;
    const electionContext = useElection();
    const { setCurrentPage, data, setDataDeliberation } = electionContext;
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [erros, setErros] = useState<any>();
    const [form, setForm] = useState({
        key: '',
    });
    const { responsibility } = data;

    function validateForm(form: any) {
        const constraints = {
            key: {
                presence: {
                    allowEmpty: false,
                    message: messageError('Informar', ' a senha')
                },
                length: {
                    minimum: characters,
                    tooShort: messageError('A senha', `deve ter ${characters} caracteres.`) // %{count}
                }
            }
        };

        // validate.validators.type.types.cpf = function (value: string) { return isValidCPF(value); };

        const v = validate({ ...form }, constraints);
        setErros(v);
        return v;
    }

    async function handleSubmit(e: any) {
        e.preventDefault();
        const v = validateForm(form);
        setIsSubmitted(true);
        if (!v) {
            setLoading(true);
            try {
                const resp = await churchGetKeyElectionGet(electionContext?.church?.id, electionContext?.data?.id, form.key.toLocaleLowerCase());
                const respElectionLink = await getDeliberationLinkToElection(electionContext?.church?.id, electionContext?.data?.id);
                
                if (resp.empty) {
                    setCurrentPage(ELECTION_INVALID_PASSWORD);
                    return;
                }

                let data$: any;
                resp.forEach((doc) => {
                    data$ = {
                        ...doc.data(),
                        ...{ id: doc.id },
                    };
                });

                electionContext.setKey(data$);
                const withoutVotes: boolean[] = [];
                responsibility.map(({ id }: any) => {
                    withoutVotes.push(!!(data$?.voted && data$?.voted[id]))
                })

                // caso exista uma deliberação lincada .
                const deliberationWithoutVotes: boolean[] = [];

                if (!respElectionLink.empty) {
                    let deliberationData$: any;
                    respElectionLink.forEach((doc) => {
                        deliberationData$ = {
                            ...doc.data(),
                            ...{ id: doc.id },
                        };
                    });

                    
                    
                    setDataDeliberation(deliberationData$);
                    deliberationData$?.deliberations?.map(({ id }: any) => {
                        deliberationWithoutVotes.push(!!(data$?.votedDeliberation && data$?.votedDeliberation[id]))
                    })
                }

                if (withoutVotes.includes(true)) {
                    setCurrentPage(ELECTION_RECEIPT);
                    return;
                }
                setCurrentPage(ELECTION_INVALID_PASSWORD);

            } catch (error) {

            } finally {
                setLoading(false);
            }
        }
    }

    function handleFormChange(value: { [key: string]: any }) {
        const newForm = {
            ...form,
            ...value
        }
        setForm(newForm);
        if (isSubmitted) {
            validateForm(newForm);
        }
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col">
            <div className="mb-5">
                <p>Para visualizar o seu comprovante de votação, digite abaixo a senha que você usou para fazer a votação.</p>
            </div>
            <div className="mb-8">
                <input
                    className={`bg-gray-200 focus:outline-none border-gray-300 py-2 px-4 block w-full appearance-none leading-normal placeholder-primary-base border ${erros?.key ? 'border-red-500' : 'border-gray-300'}`}
                    type="text"
                    placeholder="Senha"
                    value={form.key}
                    maxLength={characters}
                    onChange={(e: any) => handleFormChange({ key: e.target.value.toUpperCase() })}
                />
                {erros?.key && <p className="text-red-500 text-xs italic mt-1">{erros?.key.join('. ')}</p>}
            </div>
            <div className="flex flex-col justify-center">
                <div className="text-center mb-5">
                    <button
                        type="submit"
                        disabled={loading}
                        className={`bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded`}
                    >
                        Ver comprovante
                    </button>
                </div>
                <div>
                    <button
                        type="button"
                        className="text-primary-base font-bold transform hover:scale-105 transition duration-500 py-3 rounded focus:outline-none underline"
                        onClick={() => setCurrentPage(ELECTION_LOGIN)}
                    >
                        {'<'} Voltar
                    </button>
                </div>
            </div>
        </form>
    );
}

export default ElectionLoginReceiptPage;

