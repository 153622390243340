import React from "react";
import { useRecommendation } from "../../../contexts/use-recommendation";
import RecommendationHomePage from "./home";
import RecommendationNoMemberPage from "./noMember";
import RecommendationLoginPage from "./login";
import RecommendationFeedbackSubmitPage from "./feedbackSubmit";
import RecommendationFeedbackFullRecommendationPage from "./feedbackFullRecommendation";
import RecommendationRecommendationPage from "./recommendations";

import RecommendationIsMemberPage from "./isMember";
import ContainerTransition from "../../../components/container";

function RecommendationBasePage() {
    const recommendationContext = useRecommendation();
    const { currentPage } = recommendationContext;


    function renderSwitch() {
        switch (currentPage) {
            case 'home':
                return <RecommendationHomePage />;
            case 'noMember':
                return <RecommendationNoMemberPage />;
            case 'isMember':
                return <RecommendationIsMemberPage />;
            case 'login':
                return <RecommendationLoginPage />;
            case 'recommendations':
                return <RecommendationRecommendationPage />;
            case 'feedbackSubmit':
                return <RecommendationFeedbackSubmitPage />;
            case 'feedbackFullRecommendation':
                return <RecommendationFeedbackFullRecommendationPage />;
            default:
                return 'Nenhuma página foi encontrada';
        }
    }

    return (
        <ContainerTransition currentPage={currentPage}>
            <p className="font-bold text-lg mb-2">Indicação de Candidatos</p>
            {renderSwitch()}
        </ContainerTransition>
    )
}

export default RecommendationBasePage;

