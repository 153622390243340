import moment from "moment";
import React from "react";

export default function DeliberationResultComponent({ data, electionData }: any) {

    function renderResult() {
        return data?.deliberations?.map((item: any, index: number) => {
            const consolidated = data.consolidated[item.id];

            return (
                <div key={index} className="border pt-5 pr-5 pl-5 mb-6 border-primary-base">
                    <div className="text-center">
                        <p><strong>{item?.name?.toUpperCase()}</strong></p>
                        {/* <p><strong>DELIBERAÇÕES</strong></p> */}
                    </div>
                    <div className={`mb-2 w-full flex items-center justify-center uppercase sm:flex-1 ${consolidated?.isElected ? 'text-green-500' : 'text-red-500'} `}>
                        <strong>{consolidated?.isElected ? 'APROVADO' : 'NÃO APROVADO'}</strong>
                    </div>
                    {/* <Votantes qty={consolidated.quorum} /> */}
                    <div className="flex flex-wrap">
                        <div className="w-full sm:flex-1 py-2">
                            <p>Votos A Favor: <strong>{consolidated?.votes?.AFAVOR?.qtyVotes}</strong></p>
                            <p>Votos Contra: <strong>{consolidated?.votes?.CONTRA?.qtyVotes}</strong></p>
                            {/* <p>Votos Válidos*: <strong>{consolidated?.validMajority}</strong></p> */}
                            {/* <p>Maioria Simples: <strong>{consolidated.simple}</strong></p> */}
                        </div>
                        <div className="w-full sm:flex-1 py-2">
                            <p>Votos Brancos: <strong>{consolidated?.votes?.BRANCO?.qtyVotes}</strong></p>
                            <p>Votos Totais: <strong>{consolidated?.total}</strong></p>
                        </div>


                    </div>
                </div>
            )
        })
    }

    if (!electionData && (!data.consolidated || !data.result)) {
        return (
            <div>Resultado não consolidado</div>
        );
    }

    const quorum = data.consolidated[data?.deliberations[0]?.id]?.quorum;
    const age = quorum >= data?.minimumQuorum;

    return (
        <>
            {
                !!electionData && (
                    <>
                        <div className="text-center mb-5 mt-8">
                            <div className="font-bold text-lg">
                                {/* <p>{data?.name}</p> */}
                                <p>DELIBERAÇÕES</p>
                            </div>
                            <p><strong>{moment(electionData?.start?.seconds * 1000).format('DD/MM/YYYY')}</strong></p>
                        </div>
                    </>
                )
            }
            <div className="mb-5 -mt-5">
                {
                    !electionData && (
                        <div className="text-center">
                            <p><strong>{moment(data?.start?.seconds * 1000).format('DD/MM/YYYY')}</strong></p>
                        </div>
                    )
                }
                <div className="flex flex-wrap sm:divide-x sm:divide-gray-700 mt-4">
                    <div className="w-full sm:flex-1">
                        <p>Membros Comungantes: <strong>{data?.listOfMembers}</strong></p>
                        <p>Quórum mínimo: <strong>{data?.minimumQuorum}</strong></p>
                    </div>
                    <div className="w-full sm:flex-1 sm:pl-5">
                        <p>Votantes: <strong>{data.consolidated[data?.deliberations[0]?.id]?.quorum}</strong></p>
                        <p className={`${!age ? 'text-red-600' : ''}`}><strong>A.G.E. {!age ? 'NÃO' : ""} INSTALADA</strong></p>
                    </div>
                </div>
            </div>
            {age && renderResult()}
        </>
    )
}