import { Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { Voting } from "../../../components";
import { ELECTION_RECEIPT, ELECTION_VOTING_DELIBERATION } from "../../../constants";
import { useElection } from "../../../contexts";
import { churchSaveVoteElectionSnapshot } from "../../../services";

function ElectionVotingPage() {
    const electionContext = useElection();
    const [sending, setSending] = useState(false);
    const [selected, setSelected] = useState<Array<any>>([]);
    const [showVoting, setShowVoting] = useState(false);
    const [showVotingNull, setShowVotingNull] = useState(false);
    const [step, setStep] = useState(0);
    const [dataStep, setDataStep] = useState<any>();
    const { key, data, dataDeliberation, setCurrentPage, setTitlePage } = electionContext;
    const { responsibility } = data;

    useEffect(() => {
        window.scroll(0, 0);
        if (key?.voted && key?.voted[responsibility[step].id]) {
            nextStep();
        } else {
            const newDataStep = { ...responsibility[step] };
            setTitlePage(newDataStep.pluralName.toUpperCase());
            setDataStep(newDataStep);
            setSelected([]);
        }

    }, [step]);

    useEffect(() => {
        return () => {
            setTitlePage('');
        }
    }, []);

    function handleChange(value: any) {
        const filters = dataStep.person.filter((item: any) => value.includes(item.id));
        setSelected(filters);
    }

    function nextStep() {
        if (step + 1 < (responsibility?.length || 0)) {
            setStep(step + 1);
            setSending(false);
        } else if (dataDeliberation?.deliberations?.length > 0) {
            setCurrentPage(ELECTION_VOTING_DELIBERATION);
        } else {
            setCurrentPage(ELECTION_RECEIPT);
        }
    }

    function getVotes(allNull = false) {
        const arr = [...new Array(Number(dataStep.qty)).fill('')];
        if (allNull) {
            return arr.map(() => 'nulo');
        }
        return arr.map((emptyVal, index) => {
            return selected[index]?.id || 'branco';
        })
    }

    async function handleVotes(allNull = false) {
        setShowVoting(false);
        setShowVotingNull(false);
        setSending(true);
        try {
            await churchSaveVoteElectionSnapshot(key.id, dataStep.id, getVotes(allNull))
            nextStep();
        } catch (error) {
            alert('Algo deu errado. Tente novamente');
        } finally {
            setSending(false);
        }
    }

    function renderContent() {
        if (sending) {
            return (
                <div className="text-center space-y-5">
                    <p><strong>Registrando votos</strong></p>
                    <Spin />
                </div>
            )
        } else {
            return (
                <div className="pb-16">
                    {
                        selected.length > 0 && (
                            <div className="fixed z-10 top-0 sm:top-auto sm:bottom-0 left-0 bg-white w-full p-5 border border-primary-base rounded-b-lg sm:rounded-b-none sm:rounded-t-lg text-center transition-all duration-500 animation-voting-qty">
                                Candidatos selecionados: <strong className="text-base">{selected.length} de {dataStep.qty}</strong>
                            </div>
                        )
                    }
                    <Voting key={step} data={dataStep} onChange={handleChange} />
                    <div className="mt-10 space-y-5">
                        <button
                            onClick={() => setShowVoting(true)}
                            type="button"
                            className="w-full text-primary-contrast transform hover:scale-101 transition duration-500 py-3 rounded focus:outline-none"
                            style={{ background: 'green' }}
                        >
                            <span className="block text-2xl">VOTAR</span>
                            {/* <small>para {dataStep.pluralName}</small> */}
                        </button>
                        <button
                            onClick={() => setShowVotingNull(true)}
                            type="button"
                            className="w-full bg-gray-600 text-white transform hover:scale-101 transition duration-500 py-3 rounded focus:outline-none"
                        >
                            <span className="block text-2xl">ANULAR</span>
                            {/* <small>todos os votos para {dataStep.pluralName}</small> */}
                        </button>
                    </div>
                </div>
            )
        }
    }

    if (!dataStep) {
        return null;
    }

    return (
        <>
            {renderContent()}
            <Modal
                title={<strong>Confirme seu(s) voto(s) para {dataStep.pluralName}</strong>}
                visible={showVoting}
                onOk={() => handleVotes(false)}
                onCancel={() => setShowVoting(false)}
                okText="Confirmar"
                cancelText="Alterar"
                width={460}
            >
                <p className="mb-5"><strong>Você selecionou {selected.length} de {dataStep.qty} candidato(s) para {dataStep.pluralName.toUpperCase()}?</strong></p>
                {
                    [...new Array(Number(dataStep.qty)).fill('')].map((emptyVal, index) => {
                        const position = index + 1;
                        const name = selected[index]?.name || 'Branco';
                        return <p key={index}>{position < 10 ? '0' : ''}{position} - {name}</p>;
                    })
                }
            </Modal>
            <Modal
                // title={<strong>ANULAR seus votos para {dataStep.pluralName}</strong>}
                title={<strong>ANULAR</strong>}
                visible={showVotingNull}
                onOk={() => handleVotes(true)}
                onCancel={() => setShowVotingNull(false)}
                okText="Confirmar"
                cancelText="Cancelar"
                width={460}
            >
                <p className="mb-5"><strong>Você deseja ANULAR seu(s) voto(s) para {dataStep.pluralName.toUpperCase()}?</strong></p>
                <p>Ao anular, <strong>{dataStep.qty}</strong> voto(s) para {dataStep.pluralName} será(ão) considerado(s) <strong>NULO(S)</strong>.</p>
            </Modal>
        </>
    )
}

export default ElectionVotingPage;

