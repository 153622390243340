import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import './assets/css'
import { ProvideFirebase, ProvideLayout } from './contexts';

ReactDOM.render(
  <React.StrictMode>
    <ProvideFirebase>
      <ProvideLayout>
        <Routes />
      </ProvideLayout>
    </ProvideFirebase>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
