import firebase from "firebase/app";
import React, { useEffect, useRef, useState } from "react";
import ContainerTransition from "../../../components/container";
import { DELIBERATION_INVALID_PASSWORD, DELIBERATION_LOADING, DELIBERATION_LOGIN, DELIBERATION_LOGIN_RECEIPT, DELIBERATION_RECEIPT, DELIBERATION_RESULT, DELIBERATION_RESULT_COUNTING, DELIBERATION_VOTING } from "../../../constants";
import { useDeliberation } from "../../../contexts/use-deliberation";
import DeliberationInvalidPasswordPage from "./invalidPassword";
import DeliberationLoginPage from "./login";
import DeliberationLoginReceiptPage from "./loginReceipt";
import DeliberationReceiptPage from "./receipt";
import DeliberationResultPage from "./result";
import DeliberationResultCountingPage from "./resultCounting";
import DeliberationVotingPage from "./voting";

function DeliberationBasePage() {
    const deliberationContext = useDeliberation();
    const { currentPage, setCurrentPage, titlePage, data } = deliberationContext;
    const [delayInterval, setDelayInterval] = useState<number | null>(null);

    useEffect(() => {
        setDelayInterval(1000);
        return () => {
            setDelayInterval(null);
        }
    }, []);

    useInterval(() => {
        const t = firebase.firestore.Timestamp.now().seconds;
        const ended = t > data.end.seconds;
        // console.log('aki', ended, data.result);
        let page: any;
        if (ended && data.result) {
            if(currentPage !== DELIBERATION_LOGIN_RECEIPT && currentPage !== DELIBERATION_INVALID_PASSWORD && currentPage !== DELIBERATION_RECEIPT && currentPage !== DELIBERATION_RESULT) {
                page = DELIBERATION_RESULT;
            }
            // } else if (data.apurando && !data.result) {
        } else if (ended && !data.result) {
            page = DELIBERATION_RESULT_COUNTING;
            // setDelayInterval(null);
            // alert('Votação encerrada!');
            // window.location.reload();
        }
        
        if (!page) {
            if(currentPage === DELIBERATION_LOADING) {
                setCurrentPage(DELIBERATION_LOGIN);
            }
        } else {
            setCurrentPage(page);
        }
    }, delayInterval);

    function useInterval(callback: () => void, delay: number | null) {
        const savedCallback: any = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }


    function renderSwitch() {
        switch (currentPage) {
            case DELIBERATION_LOGIN:
                return <DeliberationLoginPage />;
            case DELIBERATION_LOGIN_RECEIPT:
                return <DeliberationLoginReceiptPage />;
            case DELIBERATION_INVALID_PASSWORD:
                return <DeliberationInvalidPasswordPage />;
            case DELIBERATION_RECEIPT:
                return <DeliberationReceiptPage />;
            case DELIBERATION_RESULT:
                return <DeliberationResultPage />;
            case DELIBERATION_RESULT_COUNTING:
                return <DeliberationResultCountingPage />;
            case DELIBERATION_VOTING:
                return <DeliberationVotingPage />;
            case DELIBERATION_LOADING:
                return 'Carregando...';
            default:
                return 'Nenhuma página foi encontrada';
        }
    }

    if (currentPage === DELIBERATION_LOADING) {
        return null;
    }

    return (
        <ContainerTransition currentPage={currentPage}>
            <p className={`font-bold text-lg mb-5 ${titlePage ? 'text-center' : ''}`}>{titlePage ? titlePage : 'Assembleia Deliberativa'}</p>
            {renderSwitch()}
        </ContainerTransition>
    )
}

export default DeliberationBasePage;

