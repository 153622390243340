import React, { FC } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

const ContainerTransition: FC<any> = ({ children, currentPage }) => {
    return (
        <div className="flex flex-col justify-center mb-10">
            <div className="text-primary-contrast font-bold text-xl my-5 text-center">Assembleia Geral Extraordinária</div>
            {/* <div className="text-primary-contrast text-xl mb-5 text-center">ELEIÇÕES</div> */}
            <SwitchTransition mode="out-in">
                <CSSTransition
                    key={currentPage}
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                    }}
                    classNames="fade"
                >
                    <div className="overflow-hidden sm:overflow-initial">
                        <div className="w-full box-animate">
                            <div className="w-full lg:max-w-full lg:flex px-5">
                                <div className="flex-1 bg-primary-contrast shadow-lg px-6 py-8 flex flex-col">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </SwitchTransition>
        </div >
    )
}

export default ContainerTransition;