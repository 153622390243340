import DeliberationResultComponent from "components/deliberationResult/deliberationResult";
import React, { useEffect } from "react";
import { DELIBERATION_LOGIN_RECEIPT } from "../../../constants";
import { useDeliberation } from "../../../contexts";

function DeliberationResultPage() {
    const deliberationContext = useDeliberation();
    const { setCurrentPage, setTitlePage, data } = deliberationContext;

    useEffect(() => {
        setTitlePage('RESULTADO DA ASSEMBLEIA DELIBERATIVA');
        return () => {
            setTitlePage('');
        }
    }, []);


    return (
        <>
            <DeliberationResultComponent data={data} />
            <div className="text-center">
                <button
                    type="button"
                    className="text-base text-primary-base font-bold transform hover:scale-105 transition duration-500 py-3 rounded focus:outline-none underline"
                    onClick={() => setCurrentPage(DELIBERATION_LOGIN_RECEIPT)}
                >
                    Meu comprovante
                </button>
            </div>
        </>
    )
}

export default DeliberationResultPage;

