import React from "react";
import { useRecommendation } from "../../../contexts/use-recommendation";

function RecommendationIsMemberPage() {
    const recommendationContext = useRecommendation();
    const { setCurrentPage } = recommendationContext;

    function handleNoMember() {
        setCurrentPage(0);
    }

    function handleLogin() {
        setCurrentPage(3)
    }

    return (
        <>
            <div className="mb-8">
                <div className="my-5">
                    <p>Apenas os membros da Igreja podem fazer indicações.</p>
                </div>
                <div className="text-center">
                    <p className="text-lg">
                        <strong>Você é membro da Igreja?</strong>
                    </p>
                </div>
            </div>
            <div className="text-center">
                <button type="button" className="bg-gray-700 transform hover:scale-105 transition duration-500 opacity-75 text-white py-3 px-8 rounded mr-4" onClick={handleNoMember}>Não</button>
                <button type="button" className="bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded ml-4" onClick={handleLogin}>Sim</button>
            </div>
        </>
    )
}

export default RecommendationIsMemberPage;

