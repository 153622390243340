import { Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { churchSaveVoteDeliberationSnapshot } from "services";

function DeliberationVoteComponent({ deliberations, electionKey, setTitlePage, nextPage }: any) {
    const [sending, setSending] = useState(false);
    const [showVoting, setShowVoting] = useState<'AFAVOR' | 'CONTRA' | 'BRANCO' | undefined>(undefined);
    const [step, setStep] = useState(0);
    const [dataStep, setDataStep] = useState<any>();

    useEffect(() => {
        window.scroll(0, 0);
        if (electionKey?.votedDeliberation && electionKey?.votedDeliberation[deliberations[step]?.id]) {
            nextStep();
        } else {
            const newDataStep = { ...deliberations[step] };
            setTitlePage(newDataStep.name?.toUpperCase());
            setDataStep(newDataStep);
        }
    }, [step]);

    useEffect(() => {
        return () => {
            setTitlePage('');
        }
    }, []);

    function nextStep() {
        if (step + 1 < deliberations.length) {
            setStep(step + 1);
            setSending(false);
        } else {
            nextPage();
        }
    }

    async function handleVotes(value: 'AFAVOR' | 'CONTRA' | 'BRANCO') {
        setShowVoting(undefined);
        setSending(true);

        try {
            await churchSaveVoteDeliberationSnapshot(electionKey.id, dataStep.id, value)
            nextStep();
        } catch (error) {
            alert('Algo deu errado. Tente novamente');
        } finally {
            setSending(false);
        }
    }

    function renderContent() {
        if (sending) {
            return (
                <div className="text-center space-y-5">
                    <p><strong>Registrando votos</strong></p>
                    <Spin />
                </div>
            )
        } else {
            return (
                <div className="pb-16">
                    <div className="space-y-5">
                        <p>Para votar, escolha a opção <strong>A FAVOR</strong>, <strong>CONTRA</strong> ou <strong>BRANCO</strong> e confirme seu voto:</p>
                        {dataStep?.photo && <img className="mx-auto" src={dataStep?.photo} alt="" />}
                        <p>{dataStep?.description}</p>
                    </div>
                    <div className="mt-10 space-y-5">
                        <div className="grid grid-cols-2 gap-5">
                            <button
                                onClick={() => setShowVoting('CONTRA')}
                                type="button"
                                className="w-full bg-red-700 text-primary-contrast transform hover:scale-101 transition duration-500 py-3 rounded focus:outline-none"
                            >
                                <strong className="block text-2xl">CONTRA</strong>
                                {/* <small>para {dataStep.pluralName}</small> */}
                            </button>
                            <button
                                onClick={() => setShowVoting('AFAVOR')}
                                type="button"
                                className="w-full text-primary-contrast transform hover:scale-101 transition duration-500 py-3 rounded focus:outline-none"
                                style={{ background: 'green' }}
                            >
                                <strong className="block text-2xl">A FAVOR</strong>
                                {/* <small>para {dataStep.pluralName}</small> */}
                            </button>
                        </div>
                        <button
                            onClick={() => setShowVoting('BRANCO')}
                            type="button"
                            className="w-full bg-white  transform hover:scale-101 transition duration-500 py-3 rounded focus:outline-none border border-black rounded-lg"
                        >
                            <strong className="block text-2xl text-black">BRANCO</strong>
                            {/* <small>todos os votos para {dataStep.pluralName}</small> */}
                        </button>
                    </div>
                </div>
            )
        }
    }

    if (!dataStep) {
        return null;
    }

    return (
        <>
            {renderContent()}
            <Modal
                title={<strong>Confirme sua escolha</strong>}
                visible={showVoting === 'AFAVOR'}
                onOk={() => handleVotes('AFAVOR')}
                onCancel={() => setShowVoting(undefined)}
                okText="Confirmar"
                cancelText="Alterar"
                width={460}
            >
                <div className="text-green-500 py-10 flex justify-center items-center text-5xl">
                    <strong>A FAVOR</strong>
                </div>
            </Modal>
            <Modal
                title={<strong>Confirme sua escolha</strong>}
                visible={showVoting === 'CONTRA'}
                onOk={() => handleVotes('CONTRA')}
                onCancel={() => setShowVoting(undefined)}
                okText="Confirmar"
                cancelText="Alterar"
                width={460}
            >
                <div className="text-red-500 py-10 flex justify-center items-center text-5xl">
                    <strong>CONTRA</strong>
                </div>
            </Modal>
            <Modal
                title={<strong>Confirme sua escolha</strong>}
                visible={showVoting === 'BRANCO'}
                onOk={() => handleVotes('BRANCO')}
                onCancel={() => setShowVoting(undefined)}
                okText="Confirmar"
                cancelText="Alterar"
                width={460}
            >
                <div className="text-black py-10 flex justify-center items-center text-5xl">
                    <strong>BRANCO</strong>
                </div>
            </Modal>
        </>
    )
}

export default DeliberationVoteComponent;

