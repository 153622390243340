import { notification, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ELECTION_LOGIN } from "../../../constants";
import { useElection } from "../../../contexts";
import { churchGetKeyElectionGet } from "../../../services";

function ElectionReceiptPage() {
    const electionContext = useElection();
    const [loading, setLoading] = useState(false);
    const { setTitlePage, setCurrentPage, data, dataDeliberation, key } = electionContext;
    const { responsibility } = data;

    useEffect(() => {
        setTitlePage('COMPROVANTE DE VOTAÇÃO');
        getDataKey();
        return () => {
            electionContext.setKey(undefined);
            setTitlePage('');
        }
    }, []);

    async function getDataKey() {
        setLoading(true);

        try {
            const resp = await churchGetKeyElectionGet(electionContext?.church?.id, electionContext?.data?.id, key.key.toLocaleLowerCase());

            if (resp.empty) {
                return;
            }

            let data$: any;
            resp.forEach((doc) => {
                data$ = {
                    ...doc.data(),
                    ...{ id: doc.id },
                };
            });

            electionContext.setKey(data$);
        } catch (error) {
            notification.error({ message: 'Algo deu errado. Tente novamente.' });
        } finally {
            setLoading(false);
        }
    }

    function renderDate() {
        return responsibility.map(({ id, pluralName }: any) => {
            return (
                <p key={id}>
                    Votação para <strong>{pluralName.toUpperCase()}</strong>: {
                        key?.voted && key?.voted[id]
                            ? moment((key?.voted[id].createdAt.seconds * 1000)).format("DD/MM/YYYY [às] HH:mm")
                            : "Voto(s) não realizados"
                    }
                </p>
            )
        })
    }
    
    function renderDateDeliberation() {
        return dataDeliberation?.deliberations?.map(({ id, name }: any) => {
            return (
                <p key={id}>
                    Votação <strong>{name?.toUpperCase()}</strong>: {
                        key?.votedDeliberation && key?.votedDeliberation[id]
                            ? moment((key?.votedDeliberation[id]?.createdAt?.seconds * 1000)).format("DD/MM/YYYY [às] HH:mm")
                            : "Voto(s) não realizados"
                    }
                </p>
            )
        })
    }

    return (
        <div className="text-center">
            <div>
                <p>Votação realizada com sucesso!</p>
                <p className="text-6xl">
                    <i className="uil uil-check-circle"></i>
                </p>
            </div>
            <div className="mb-5">
                <p><strong>Mostre esse comprovante ao mesário para encerrar sua participação.</strong></p>
                {/* <p>Veja abaixo o comprovante da sua votação:</p> */}
            </div>
            {
                loading
                    ? <Spin size="large" />
                    : (<>
                        {renderDate()}
                        {renderDateDeliberation()}
                    </>)
            }

            <div className="mt-6">
                <button
                    type="button"
                    className={`bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded`}
                    onClick={() => setCurrentPage(ELECTION_LOGIN)}
                >
                    Início
                </button>
            </div>
        </div>
    )
}

export default ElectionReceiptPage;

