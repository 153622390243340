import React, {
  useContext,
  createContext,
  FunctionComponent,
  useState,
} from 'react';
import { LayoutProps } from './use-layout.props';

const layoutContext = createContext({} as LayoutProps);

export const useLayout = (): LayoutProps => {
  return useContext(layoutContext);
};

function useProvideLayout(): LayoutProps {
  const [isOpenUserForm, setIsOpenUserForm] = useState<boolean>(false);
  const [dataUserForm, setDataUserForm] = useState<any>();
  const [selectedPage, setSelectedPage] = useState<any>(undefined); // quando seleciona o card que vai iniciar a navegação
  return {
    isOpenUserForm,
    setIsOpenUserForm,
    dataUserForm, 
    setDataUserForm,
    selectedPage,
    setSelectedPage
  };
}

export const ProvideLayout: FunctionComponent = ({ children }) => {
  const layout: LayoutProps = useProvideLayout();
  return <layoutContext.Provider value={layout}>{children}</layoutContext.Provider>;
};