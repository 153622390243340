import DeliberationVoteComponent from "components/deliberationVote/deliberationVote";
import React from "react";
import { DELIBERATION_RECEIPT } from "../../../constants";
import { useDeliberation } from "../../../contexts";

function DeliberationVotingPage() {
    const deliberationContext = useDeliberation();
    const { key, data, setCurrentPage, setTitlePage } = deliberationContext;
    const { deliberations } = data;
    
    function nextPage() {
        setCurrentPage(DELIBERATION_RECEIPT);
    }

    return (
        <DeliberationVoteComponent
            deliberations={deliberations}
            electionKey={key}
            setTitlePage={setTitlePage}
            nextPage={nextPage}
        />
    )
}

export default DeliberationVotingPage;

