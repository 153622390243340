import React, { useState } from "react";
import { useRecommendation } from "../../../contexts/use-recommendation";
import VMasker from 'vanilla-masker';
import { isValidCPF, messageError } from "../../../utils";
const validate = require("validate.js");

function RecommendationLoginPage() {
    const recommendationContext = useRecommendation();
    const { setCurrentPage } = recommendationContext;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [erros, setErros] = useState<any>();
    const [form, setForm] = useState({
        name: '',
        cpf: '',
        phone: ''
    });

    function handleBack() {
        setCurrentPage(1);
    }

    function validateForm(form: any) {
        const constraints = {
            name: {
                presence: {
                    allowEmpty: false,
                    message: messageError('Nome')
                }
            },
            cpf: {
                type: {
                    type: 'cpf',
                    message: messageError('CPF', 'inválido')
                }
            },
            phone: {
                length: {
                    minimum: 14,
                    tooShort: messageError('Telefone', 'inválido') // %{count}
                }
            }
        };
        validate.validators.type.types.cpf = function (value: string) { return isValidCPF(value); };

        const v = validate({ ...form }, constraints);
        setErros(v);
        return v;
    }

    function handleLogin() {
        const { setUser } = recommendationContext;
        setUser({ ...form });
        setCurrentPage(4);
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        const v = validateForm(form);
        setIsSubmitted(true);
        if (!v) {
            handleLogin();
        }
    }

    function handleFormChange(value: { [key: string]: any }) {
        const newForm = {
            ...form,
            ...value
        }
        setForm(newForm);
        if (isSubmitted) {
            validateForm(newForm);
        }
    }

    function changePhone(e: any) {
        const value = e.target.value;
        let mask = value.length <= 14 ? '(99) 9999-9999' : '(99) 99999-9999';
        const phone = VMasker.toPattern(e.target.value, mask);
        handleFormChange({ phone })
    }

    return (
        <>
            <div className="mb-2">
                <div className="my-5">
                    <p>Para fazer suas indicações, informe os seguintes dados:</p>
                </div>
            </div>
            <div className="w-full">
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <input
                            autoComplete="off"
                            className={`shadow appearance-none w-full py-3 px-3 text-primary-base placeholder-primary-base leading-tight focus:outline-none focus:shadow-outline bg-gray-100 ${erros?.name ? 'border-red-500' : ''}`}
                            autoFocus
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Seu nome"
                            value={form.name}
                            onChange={(e: any) => handleFormChange({ name: e.target.value })}
                        />
                        {erros?.name && <p className="text-red-500 text-xs italic mt-1">{erros?.name.join('\n')}</p>}
                    </div>
                    <div className="mb-4">
                        <input
                            autoComplete="off"
                            className={`shadow appearance-none w-full py-3 px-3 text-primary-base placeholder-primary-base leading-tight focus:outline-none focus:shadow-outline bg-gray-100 ${erros?.cpf ? 'border-red-500' : ''}`}
                            id="user"
                            name="cpf"
                            type="tel"
                            placeholder="Seu CPF"
                            value={form.cpf}
                            onChange={(e: any) => handleFormChange({ cpf: VMasker.toPattern(e.target.value, "999.999.999-99") })}
                        />
                        {erros?.cpf && <p className="text-red-500 text-xs italic mt-1">{erros?.cpf.join('\n')}</p>}
                    </div>
                    <div className="mb-6">
                        <input
                            autoComplete="off"
                            className={`shadow appearance-none w-full py-3 px-3 text-primary-base placeholder-primary-base leading-tight focus:outline-none focus:shadow-outline bg-gray-100 ${erros?.phone ? 'border-red-500' : ''}`}
                            id="telefone"
                            name="telefone"
                            type="tel"
                            placeholder="Seu Telefone"
                            value={form.phone}
                            onChange={changePhone}
                        />
                        {erros?.phone && <p className="text-red-500 text-xs italic mt-1">{erros?.phone.join('\n')}</p>}
                    </div>
                    <div className="flex justify-between">
                        <button
                            type="button"
                            className="text-primary-base font-bold transform hover:scale-105 transition duration-500 py-3 rounded mr-4 focus:outline-none"
                            onClick={handleBack}
                        >
                            {'<'} Voltar
                        </button>
                        <button
                            type="submit"
                            className={`bg-primary-base transform hover:scale-105 transition duration-500 text-primary-contrast py-3 px-10 rounded ml-4`}
                        >
                            Iniciar
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default RecommendationLoginPage;

